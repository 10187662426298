import Vue from 'vue'
import App from './App.vue'
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
// import { getDatabase } from "firebase/database"

const firebaseConfig = {
  apiKey: "AIzaSyApNjGtMTfWTMjUnCf45ehGyF6Y5y1IFhg",
  authDomain: "neuro-jim.firebaseapp.com",
  projectId: "neuro-jim",
  storageBucket: "neuro-jim.appspot.com",
  messagingSenderId: "481757734126",
  appId: "1:481757734126:web:ae54200a98773b5438ffff",
  measurementId: "G-TL996K6TG9",
  databaseURL: "https://neuro-jim-default-rtdb.firebaseio.com"
}

const app = initializeApp(firebaseConfig)
// export const db = getDatabase(app)
getAnalytics(app)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
