<template>
  <div id="app">
    <div id="stopwatch">
      <clock :style="{visibility: start ? 'visible' : 'hidden'}" ref="clock" />
    </div>
    <div id="card-slider">
      <flickity v-if="!flip" class="carousel" ref="flickity" :options="flickityOptions">
        <div v-for="(item,i) of items" :key="i" class="carousel-cell">
          <img :src="require(`@/assets/films/${item.num}.svg`)">
        </div>
      </flickity>
      <vue-flip v-else v-model="answer"  width="300px" height="418px" class="carousel-cell">
        <template v-slot:front>
          <img :src="require(`@/assets/films/0.svg`)">
        </template>
        <template v-slot:back>
          <div v-if="correct" class="result success">Correct!</div>
          <div v-else class="result error">Incorrect!<br>Please try again</div>
          <img :src="require(`@/assets/films/${last.num}.svg`)">
        </template>
      </vue-flip>
    </div>
    <div v-if="flip && !answer" id="answers">
      <div @click="checkAnswer(answer.val)" class="answer" v-for="(answer,i) in answers" :key="i">{{ answer.face }}</div>
    </div>
    <div class="reset" v-if="answer">
      <span @click="reset">Reset</span>
    </div>
  </div>
</template>

<script>
// import { db } from "@/main"
// import { ref, set} from "firebase/database"
import Flickity from "vue-flickity"
import imagesLoaded from "flickity-imagesloaded"
import VueFlip from "vue-flip"
import Clock from './Clock.vue'
export default {
  name: 'App',
  async created() {
    await this.startUp()
  },
  components: {
    Flickity,
    Clock,
    VueFlip
  },
  data() {
    return {
      answers: [
        {
          face: "A",
          val: 1
        },
        {
          face: "2",
          val: 2
        },
        {
          face: "3",
          val: 3
        },
        {
          face: "4",
          val: 4
        },
        {
          face: "5",
          val: 5
        },
        {
          face: "6",
          val: 6
        },
        {
          face: "7",
          val: 7
        },
        {
          face: "8",
          val: 8
        },
        {
          face: "9",
          val: 9
        },
        {
          face: "10/J/Q/K",
          val: 0
        },
      ],
      answer: false,
      flip: false,
      current: null,
      start: false,
      scroll: false,
      correct: false,
      last: null,
      items: [],
      flickityOptions: {
        resize: true,
        imagesLoaded,
        prevNextButtons: true,
        pageDots: false
      }
    }
  },
  watch: {
    current(val) {
      if (val.last) this.$refs.clock.stop()
      // console.log("current", val)
      // if (val.first) {

      //   this.items.shift()
      // } 
      // if (val === 0 && !this.scroll) {
      //   this.items.shift()
      //   this.scroll = true
      // }
      // else if (val === this.items.length) {
      //   this.items = [0]
      //   this.scroll = false
      // }

      // set(ref(db, '/'), val.num)
    }
  },
  methods: {
    async startUp() {
      this.correct = false
      this.items = this.shuffle()
      this.current = this.items[0]
      await this.$nextTick()
      this.$refs.clock.reset()
      this.$refs.flickity.on('change', async (num) => {
        if (num === 1 && !this.start) {
          this.$refs.flickity.remove(this.$refs.flickity.$flickity.cells[0].element)
          this.start = true
          this.current = this.items[num]
          await this.$nextTick()
          this.$refs.clock.start()
        }
        else this.current = this.items[num + 1]
      })
      this.$refs.flickity.on('staticClick', async (event, pointer, cellElement, cellIndex) => {
        if (cellIndex === 0 && !this.start) {
          this.$refs.flickity.remove(this.$refs.flickity.$flickity.cells[0].element)
          this.start = true
          this.current = this.items[1]
          await this.$nextTick()
          this.$refs.clock.start()
        }
      })
      this.$refs.flickity.on('settle', async () => {
        if (this.current.last) {
          this.flip = true
        }
      })
    },
    async reset() {
      this.answer = false
      this.flip = false
      this.start = false
      await this.startUp()
    },
    filter(num) {
      let all = {
        '0': [4,26,30,49,32,16,1,29,20,9,40,15,21,24,14,45],
        '1': [39,17,34,12],
        '2': [18,22,2,38],
        '3': [3,13,43,10],
        '4': [51,44,19,11],
        '5': [41,42,50,35],
        '6': [46,31,47,52],
        '7': [25,7,27,37],
        '8': [8,48,28,33],
        '9': [36,5,23,6]
      }
      let [[res]] = Object.entries(all).filter(([,val]) => {
        return val.includes(num)
      })
      return Number(res)
    },
    checkAnswer(val) {
      if (this.last.val === val) this.correct = true
      this.answer = true
    },
    shuffle() {
      let deck = [...Array(52).keys()].map(item => {
        return {
          num: item + 1,
          val: this.filter(item + 1)
        }
      }).sort(() => Math.random() - .5)
      this.last = deck.pop()
      deck[0].first = true
      deck.unshift({
        num: 0
      })
      deck.push({
        num: 0,
        last: true
      })
      // deck.push(this.last)
      return deck
    }
  },
}
</script>

<style lang="scss">
* {
  touch-action: manipulation;
}
body {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#card-slider {
  margin: 1rem 0 0;
}
#stopwatch {
  margin-top: 1rem;
}
.result {
  position: absolute;
  font-size: 1.5rem;
  top: 46%;
  left: 33%;
  border: 1px solid #000;
  padding: 0.25rem 0.5rem;
  color: #fff;
  &.success {
    background: #009900;
  }
  &.error {
    background: #e44144;
    top: 40%;
    left: 19%;
  }
}
.flickity-viewport {
  // height: 100vh !important;
  // width: 100%;
}
// .carousel{
//   // @media (max-width: )
//   img {
//     width: 75%;
//   }
// }
.flip-container {
  padding-bottom: 6px;
}
#answers {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .answer {
    padding: 0.75rem;
    border: 1px solid #000;
    background: #db3c56;
    color: #fff;
    margin: 0.25rem;
    cursor: pointer;
    font-size: .75rem;
  }
}
.reset {
  span {
    margin-top: 1rem;
    cursor: pointer;
    font-size: 1.25rem;
    display: inline-block;
    border: 1px solid #000;
    background: #e44144;
    color: #fff;
    padding: 0.5rem 1.5rem;
  }
}
.carousel-cell {
  width: 100%;
  // padding: 1rem;
  margin: 0 auto;
  img {
    width: 300px;
    height: 418px;
  }
  .front, .back {
    width: 100%;
    height: inherit;
  }
  @media (max-width: 425px) {
    img {
      // width: 85%;
    }
  }
}
</style>
