<template>
    <div id="clock">
        <span class="time">{{ time }}</span>
    </div>
</template>

<script>
export default {
    data() {
        return {
            time: "00:00:00.000",
            timeBegan: null,
            timeStopped: null,
            stoppedDuration: 0,
            started: null,
            running: false
        }
    },
    methods: {
        start() {
            if(this.running) return
            if (this.timeBegan === null) {
                this.reset();
                this.timeBegan = new Date();
            }
            if (this.timeStopped !== null) {
                this.stoppedDuration += (new Date() - this.timeStopped);
            }
            this.started = setInterval(this.clockRunning, 10)
            this.running = true
        },
        stop() {
            this.running = false
            this.timeStopped = new Date()
            clearInterval(this.started)
        },
        reset() {
            this.running = false
            clearInterval(this.started)
            this.stoppedDuration = 0
            this.timeBegan = null
            this.timeStopped = null
            this.time = "00:00:00.000"
        },
        clockRunning() {
            let currentTime = new Date()
            let timeElapsed = new Date(currentTime - this.timeBegan - this.stoppedDuration)
            let hour = timeElapsed.getUTCHours()
            let min = timeElapsed.getUTCMinutes()
            let sec = timeElapsed.getUTCSeconds()
            let ms = timeElapsed.getUTCMilliseconds();

            this.time = 
                this.zeroPrefix(hour, 2) + ":" + 
                this.zeroPrefix(min, 2) + ":" + 
                this.zeroPrefix(sec, 2) + "." + 
                this.zeroPrefix(ms, 3);
        },
        zeroPrefix(num, digit) {
            let zero = ''
            for(let i = 0; i < digit; i++) {
                zero += '0'
            }
            return (zero + num).slice(-digit)
        }
    }
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Share+Tech+Mono');

html, body {
  padding: 0; margin: 0;
  width: 100%;
  height: 100%;
}

body {
  background-color: rgb(10, 10, 10);
  font-family: 'Share Tech Mono', sans-serif;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
}

#clock {
  order: 0;
  flex: 0 1 auto;
  align-self: center;

  color: black;
  /* text-shadow: 0px 0px 25px black; */

  .time {
    font-size: 1.5rem;
  }

  .text {
    margin-top: 30px;
    font-size: 1em;
    color: rgba(black, .15);
    text-align: center;

    a {
      text-decoration: none;
      color: inherit;

      transition: color .1s ease-out;

      &:hover {
        color: black;
      }
    }
  }
}
</style>